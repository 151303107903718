export const openCageGeocodingAPIKey = "29b6206ee4fd439cb26e759d485f4f62";

export const BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://api.adoptapet.soon.it"
    : "http://localhost:3001";

export const defaultPetCards = [
  {
    _id: 1,
    petID: "cat_8",
    animalType: "Cat",
    petAge: "Junior",
    petDescription: "Very playful!",
    petStatus: "available",
    imageUrl: "https://i.imgur.com/VCdAL5A.jpg",
    ownerID: "1",
    shelter: "Amutat Linor",
    city: "Ramat Gan, Tel Aviv district",
    coordinates: '{ "lat": 32.0686867, "lng": 34.8246812 }',
    shelterEmail: "linor@gmail.com",
    likes: ["12"],
  },
  {
    _id: 4,
    petID: "dog_6",
    animalType: "Dog",
    petAge: "Junior",
    petDescription: "Needs to be trained",
    petStatus: "available",
    imageUrl: "https://i.imgur.com/cBJA0l9.jpg",
    ownerID: "2",
    shelter: "New Life",
    city: "Haifa",
    coordinates: '{ "lat": 32.8191218, "lng": 34.9983856 }',
    shelterEmail: "newlife@gmail.com",
    likes: ["45", "1"],
  },
  {
    _id: 3,
    petID: "dog_4",
    animalType: "Dog",
    petAge: "Adult",
    petDescription: "Great companion for long walks",
    petStatus: "notAvailable",
    imageUrl: "https://i.imgur.com/Bu13d8n.jpg",
    ownerID: "1",
    shelter: "Amutat Linor",
    city: "Ramat Gan, Tel Aviv district",
    coordinates: '{ "lat": 32.0686867, "lng": 34.8246812 }',
    shelterEmail: "linor@gmail.com",
    likes: ["33", "45"],
  },
  {
    _id: 5,
    petID: "dog_9",
    animalType: "Dog",
    petAge: "Senior",
    petDescription: "Prefers sleeping",
    petStatus: "available",
    imageUrl: "https://i.imgur.com/xrVIaye.jpg",
    ownerID: "3",
    shelter: "Best Friend",
    city: "Ashdod",
    coordinates: '{ "lat": 31.7957083, "lng": 34.6488831 }',
    shelterEmail: "bestfriend@gmail.com",
    likes: ["12", "33", "45"],
  },
  {
    _id: 0,
    petID: "cat_15",
    animalType: "Cat",
    petAge: "Adult",
    petDescription: "Calm and friendly",
    petStatus: "available",
    imageUrl: "https://i.imgur.com/OEgQVAi.jpg",
    ownerID: "1",
    shelter: "Amutat Linor",
    city: "Ramat Gan, Tel Aviv district",
    coordinates: '{ "lat": 32.0686867, "lng": 34.8246812 }',
    shelterEmail: "linor@gmail.com",
    likes: ["45"],
  },
  {
    _id: 2,
    petID: "cat_3",
    animalType: "Cat",
    petAge: "Senior",
    petDescription: "He doesn't hate you, it's just his face )",
    petStatus: "available",
    imageUrl: "https://i.imgur.com/GoUJCg3.jpg",
    ownerID: "2",
    shelter: "New Life",
    city: "Haifa",
    coordinates: '{ "lat": 32.8191218, "lng": 34.9983856 }',
    shelterEmail: "newlife@gmail.com",
    likes: [],
  },

  {
    _id: 7,
    petID: "rabbit_1",
    animalType: "Other",
    petAge: "Adult",
    petDescription: "Sweet rabbit - beware of his teeth!",
    petStatus: "available",
    imageUrl: "https://i.imgur.com/M8XreTs.jpg",
    ownerID: "2",
    shelter: "New Life",
    city: "Haifa",
    coordinates: '{ "lat": 32.8191218, "lng": 34.9983856 }',
    shelterEmail: "newlife@gmail.com",
    likes: ["12"],
  },

  {
    _id: 6,
    petID: "bird_1",
    animalType: "Bird",
    petAge: "Adult",
    petDescription: "Crow - clever and cunning",
    petStatus: "available",
    imageUrl: "https://i.imgur.com/wsHgl7D.jpg",
    ownerID: "1",
    shelter: "Amutat Linor",
    city: "Ramat Gan, Tel Aviv district",
    coordinates: '{ "lat": 32.0686867, "lng": 34.8246812 }',
    shelterEmail: "linor@gmail.com",
    likes: ["45, 12, 33"],
  },
];

export const defaultNewsArticles = [
  {
    _id: 1,
    articleDate: "20.08.2024",
    articleCaption: "New pets",
    articleText:
      "We're happy to announce that now we also have kittens and rabbits!",
    articleAuthor: "Best Friend",
  },
  {
    _id: 0,
    articleDate: "07.08.2024",
    articleCaption: "We're open!",
    articleText:
      "We're happy to announce that you're welcome to visit us in Ramat Gan. For details call 053-387-1111",
    articleAuthor: "Amutat Linor",
  },
];
